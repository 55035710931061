import React from 'react';
import { Link, useParams } from 'react-router-dom';

import bg1 from '../assets/images/hero/bg.jpg';

import Footer from '../componants/footer';
import Navbar from '../componants/navbar';
import ScrollTop from '../componants/scrollTop';

import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  FiClock,
  FiDollarSign,
  FiGlobe,
  FiLayout,
  FiMail,
  FiMapPin,
  FiPhone,
  FiUserCheck,
} from '../assets/icons/vander';

export default function JobDetail() {
  const { data: jobData } = useSelector((state) => state.job);
  let params = useParams();
  let id = params.id;
  let data = jobData.find((job) => job.id === parseInt(id));

  console.log('data :>> ', data);

  return (
    <>
      <Navbar navClass='defaultscroll sticky' navLight={true} />

      <section
        className='bg-half-170 d-table w-100'
        style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
        <div className='bg-overlay bg-gradient-overlay'></div>
        <div className='container'>
          <div className='row mt-5 justify-content-center'>
            <div className='col-12'>
              <div className='title-heading text-center'>
                <h5 className='heading fw-semibold mb-0 sub-heading text-white title-dark mt-3'>
                  {data?.jobTitle ? data.jobTitle : 'Job Title'}
                </h5>
              </div>
            </div>
          </div>

          <div className='position-middle-bottom'>
            <nav aria-label='breadcrumb' className='d-block'>
              <ul className='breadcrumb breadcrumb-muted mb-0 p-0'>
                <li className='breadcrumb-item'>
                  <Link to='/'>Coach & Hire</Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/jobs'>Jobs</Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Job Detail
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className='position-relative'>
        <div className='shape overflow-hidden text-white'>
          <svg
            viewBox='0 0 2880 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z'
              fill='currentColor'></path>
          </svg>
        </div>
      </div>

      <section className='section'>
        <div className='container'>
          <div className='row g-4'>
            <div className='col-lg-4 col-md-6 col-12'>
              <div className='card bg-white rounded shadow sticky-bar'>
                <div className='p-4'>
                  <h5 className='mb-0'>Job Information</h5>
                </div>

                <div className='card-body p-4 border-top'>
                  <div className='d-flex widget align-items-center'>
                    <FiLayout className='fea icon-ex-md me-3' />
                    <div className='flex-1'>
                      <h6 className='widget-title mb-0'>Company Name:</h6>
                      <small
                        className='text-primary mb-0'
                        style={{ wordBreak: 'break-all' }}>
                        {data?.companyName ? data.companyName : 'Coach & Hire'}
                      </small>
                    </div>
                  </div>

                  <div className='d-flex widget align-items-center mt-3'>
                    <FiUserCheck className='fea icon-ex-md me-3' />
                    <div className='flex-1'>
                      <h6 className='widget-title mb-0'>Employee Type:</h6>
                      <small
                        className='text-primary mb-0'
                        style={{ wordBreak: 'break-all' }}>
                        {data?.employmentType
                          ? data.employmentType
                          : 'Full Time'}
                      </small>
                    </div>
                  </div>

                  <div className='d-flex widget align-items-center mt-3'>
                    <FiMapPin className='fea icon-ex-md me-3' />
                    <div className='flex-1'>
                      <h6 className='widget-title mb-0'>Location:</h6>
                      <small
                        className='text-primary mb-0'
                        style={{ wordBreak: 'break-all' }}>
                        {data?.location ? data.location : 'USA'}
                      </small>
                    </div>
                  </div>

                  <div className='d-flex widget align-items-center mt-3'>
                    <FiDollarSign className='fea icon-ex-md me-3' />
                    <div className='flex-1'>
                      <h6 className='widget-title mb-0'>Salary:</h6>
                      <small
                        className='text-primary mb-0'
                        style={{ wordBreak: 'break-all' }}>
                        {data?.salary ? data.salary : '$'}
                      </small>
                    </div>
                  </div>

                  <div className='d-flex widget align-items-center mt-3'>
                    <FiClock className='fea icon-ex-md me-3' />
                    <div className='flex-1'>
                      <h6 className='widget-title mb-0'>Date posted:</h6>
                      <small className='text-primary mb-0 mb-0'>
                        {data?.date && moment(data.date).fromNow()}
                      </small>
                    </div>
                  </div>

                  <div className='d-flex widget align-items-center mt-3'>
                    <FiMail className='fea icon-ex-md me-3' />
                    <div className='flex-1'>
                      <h6 className='widget-title mb-0'>Email:</h6>
                      <small
                        className='text-primary mb-0 mb-0'
                        style={{ wordBreak: 'break-all' }}>
                        {data?.email && data.email}
                      </small>
                    </div>
                  </div>
                  <div className='d-flex widget align-items-center mt-3'>
                    <FiPhone className='fea icon-ex-md me-3' />
                    <div className='flex-1'>
                      <h6 className='widget-title mb-0'>Contact No</h6>
                      <small className='text-primary mb-0 mb-0'>
                        {data?.phoneNumber && data.phoneNumber}
                      </small>
                    </div>
                  </div>
                  <div className='d-flex widget align-items-center mt-3'>
                    <FiGlobe className='fea icon-ex-md me-3' />
                    <div className='flex-1'>
                      <h6 className='widget-title mb-0'>Website:</h6>
                      <small
                        className='text-primary mb-0 mb-0'
                        style={{ wordBreak: 'break-all' }}>
                        {data?.website && data.website}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-8 col-md-6 col-12'>
              <h5>Job Id: </h5>
              <div
                className='text-muted'
                dangerouslySetInnerHTML={{ __html: data?.id }}></div>
              <h5 className='mt-4'>Job Description: </h5>
              <div
                className='text-muted'
                dangerouslySetInnerHTML={{ __html: data?.jobDesc }}></div>
              <h5 className='mt-4'>Responsibilities and Duties: </h5>
              <div
                className='text-muted'
                dangerouslySetInnerHTML={{ __html: data?.duties }}></div>

              <h5 className='mt-4'>
                Required Experience, Skills and Qualifications:{' '}
              </h5>
              <div
                className='text-muted'
                dangerouslySetInnerHTML={{ __html: data?.skillDetails }}></div>
            </div>
          </div>
        </div>
      </section>
      <Footer top={true} />
      <ScrollTop />
    </>
  );
}
