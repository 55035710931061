import AxiosInstance from '../../helpers/AxiosInstance';
import { Alert } from '../../helpers/utils';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getJobs = createAsyncThunk(
  'get/jobs',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(`jobs`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const postJob = createAsyncThunk(
  'post/jobs',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.post(`jobs`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateJob = createAsyncThunk(
  'put/jobs',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.put(`jobs/${data.id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const deleteJob = createAsyncThunk(
  'delete/jobs',
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.delete(`jobs/${id}`);
      return { id: id, data: response.data };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const jobSlice = createSlice({
  name: 'jobs',
  initialState: {
    status: 'idle',
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getJobs.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getJobs.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.data = action.payload.data;
    },
    [getJobs.rejected]: (state, action) => {
      state.status = 'error';
      Alert('error', `${action.payload?.data.msg}`);
    },
    [postJob.pending]: (state, action) => {
      state.status = 'loading';
    },
    [postJob.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      Alert('success', `${action.payload.msg}`);
    },
    [postJob.rejected]: (state, action) => {
      state.status = 'error';
      Alert('error', `${action.payload?.data.msg}`);
    },
    [updateJob.pending]: (state, action) => {
      state.status = 'loading';
    },
    [updateJob.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      Alert('success', `${action.payload.msg}`);
    },
    [updateJob.rejected]: (state, action) => {
      state.status = 'error';
      Alert('error', `${action.payload.data.msg}`);
    },
    [deleteJob.pending]: (state, action) => {
      state.status = 'loading';
    },
    [deleteJob.fulfilled]: (state, action) => {
      state.data = state.data.filter(
        (da) => parseInt(da.id) !== parseInt(action.payload.id)
      );
      state.status = 'fulfilled';
      Alert('success', `${action.payload.data.msg}`);
    },
    [deleteJob.rejected]: (state, action) => {
      state.status = 'error';
      Alert('error', `${action.payload.data.msg}`);
    },
  },
});

export default jobSlice.reducer;
