import AxiosInstance from '../../helpers/AxiosInstance';
import { Alert, decodedToken, isLoggedIn } from '../../helpers/utils';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const placeOrder = createAsyncThunk(
  'post/order',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.post(`order`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const getCoaches = createAsyncThunk(
  'get/coaches',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(`user`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const coachSlice = createSlice({
  name: 'coach',
  initialState: {
    status: 'idle',
    data: [],
  },
  reducers: {},
  extraReducers: {
    [placeOrder.pending]: (state, action) => {
      state.placeOrderStatus = 'loading';
    },
    [placeOrder.fulfilled]: (state, action) => {
      state.placeOrderStatus = 'fulfilled';
      // Alert('success', `${action.payload.msg}`);
    },
    [placeOrder.rejected]: (state, action) => {
      state.placeOrderStatus = 'error';
      Alert('error', `${action.payload.data.msg}`);
    },
    [getCoaches.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getCoaches.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      let data = action.payload.data;
      if (isLoggedIn()) {
        data = action.payload.data.filter(
          (da) => da.uid !== decodedToken().uid
        );
      }
      state.data = data;
    },
    [getCoaches.rejected]: (state, action) => {
      state.status = 'error';
      Alert('error', `${action.payload?.data.msg}`);
    },
  },
});

export default coachSlice.reducer;
