import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userSlice from './slice/user-slice';
import jobSlice from './slice/job-slice';
import coachSlice from './slice/coach-slice';
import followerSlice from './slice/followers-slice';
import messageSlice from './slice/message-slice';
import { TOKEN_KEY } from '../config/config';

const persistConfig = {
  key: TOKEN_KEY,
  version: 1,
  storage,
  blacklist: [
    'product',
    'homeConfig',
    'order',
    'user',
    'job',
    'coach',
    'follower',
  ],
};

export const rootReducer = combineReducers({
  user: userSlice,
  job: jobSlice,
  coach: coachSlice,
  follower: followerSlice,
  message: messageSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
