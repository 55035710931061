import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import noConvImg from '../assets/images/no-conversations.webp';
import Navbar from '../componants/navbar';
import ScrollTop from '../componants/scrollTop';

import moment from 'moment';
import { AiFillCloseCircle, AiOutlineLoading } from 'react-icons/ai';
import { FaRegPaperPlane } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../config/config';
import { decodedToken } from '../helpers/utils';
import { getFollowers } from '../store/slice/followers-slice';
import {
  addMessageFromPusher,
  getAllMsg,
  sendMessage,
} from '../store/slice/message-slice';

import pusher from '../pusher';

export default function Messages() {
  const { followers } = useSelector((state) => state.follower);
  const { status, messages } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  let params = useParams();
  let id = params.id;

  const [toUser, setToUser] = useState({});
  const [fromUser, setFromUser] = useState({});
  const [message, setMessage] = useState('');

  // Ref for message container
  const messageEndRef = useRef(null);

  const checkIsFollowing = () => {
    let isFollowing = 'not-followers';

    if (followers) {
      const followUser = followers.find((follow) => follow.toUser === id);
      if (followUser) {
        isFollowing = followUser.status;
        setToUser(followUser.toUserDetails);
        setFromUser(followUser.fromUserDetails);
      }
    }

    return isFollowing;
  };

  useEffect(() => {
    dispatch(getAllMsg(id));
    dispatch(getFollowers());
    checkIsFollowing();
  }, [id, dispatch]);

  // Scroll to bottom
  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Trigger scroll on messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async (e) => {
    e.preventDefault();

    const newMessage = {
      fromUserId: fromUser.uid,
      fromUserDetails: fromUser,
      toUserDetails: toUser,
      toUser: id,
      message: message,
      createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    await dispatch(sendMessage(newMessage));
    setMessage('');
    scrollToBottom(); // Ensure scroll to bottom after sending
  };

  const addMessageThroughLive = async (data) => {
    if (data) {
      if (data?.toUserId === decodedToken().uid && data.fromUserId === id) {
        await dispatch(addMessageFromPusher(data));
      }
    }
  };

  useEffect(() => {
    // Subscribe to the channel
    const channel = pusher.subscribe('coachandhire');

    // Bind to the event
    channel.bind('message', (data) => {
      addMessageThroughLive(data);
    });

    // Cleanup subscription on component unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []); // Empty dependency array ensures this runs only once

  const MessageBox = ({ data }) => {
    const myUID = decodedToken()?.uid;
    const isMyMessage = data?.fromUserId === myUID;
    let show = false;

    if (isMyMessage) {
      if (data.toUserId === id) {
        show = true;
      } else {
        return;
      }
    } else {
      if (data.fromUserId === id && data.toUserId === myUID) {
        show = true;
      } else {
        return;
      }
    }

    if (!show) return null;

    return (
      <div
        className={`message-wrapper ${
          isMyMessage ? 'right-side' : 'left-side'
        }`}>
        <div
          className={`message-box card shadow  ${
            isMyMessage ? 'right-side-message' : ''
          }`}>
          <div className='card-body '>
            <div className='d-flex message-card'>
              <div className='profile'>
                <img
                  src={
                    data?.fromUserDetails?.avatar &&
                    data?.fromUserDetails?.avatar !== null &&
                    data?.fromUserDetails?.avatar !== 'null'
                      ? `${API_URL}${data?.fromUserDetails?.avatar}`
                      : `https://avatar.iran.liara.run/username?username=${data?.fromUserDetails?.name}`
                  }
                  className='img-fluid rounded-pill'
                  alt=''
                />
              </div>
              <p className='user-name'>{data?.fromUserDetails?.name}</p>
            </div>
            <div
              className={`messsage-container  ${
                isMyMessage ? 'text-end' : 'text-start'
              }`}>
              <p className={`messsage  ${isMyMessage ? 'pe-8' : 'ps-8'}`}>
                {data?.message}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Navbar navClass='defaultscroll sticky nav-dark ' navLight={true} />

      <div className='position-relative'>
        <div className='shape overflow-hidden text-white'>
          <svg
            viewBox='0 0 2880 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z'
              fill='currentColor'></path>
          </svg>
        </div>
      </div>

      <section className='section messages-section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12'>
              <div className='card shadow rounded border-0'>
                <div className='card-body'>
                  <h5 className='card-title d-flex align-items-center justify-content-between w-100'>
                    Chat
                    <Link to='/messages'>
                      <AiFillCloseCircle className='text-danger' />
                    </Link>
                  </h5>

                  {messages?.length > 0 ? (
                    <div className='message-body'>
                      {messages?.map((message, index) => (
                        <MessageBox data={message} key={index} />
                      ))}
                      {/* Scroll target */}
                      <div ref={messageEndRef} />
                    </div>
                  ) : (
                    <div className='text-center'>
                      <img
                        src={noConvImg}
                        className='img'
                        alt='No Conversation'
                        style={{ width: '300px' }}
                      />
                      <h3>No Messages found</h3>
                    </div>
                  )}

                  <div className='message-sender mt-4'>
                    <form
                      onSubmit={handleSend}
                      className='d-flex align-items-center justify-between'>
                      <input
                        className='form-control'
                        name='message'
                        type='text'
                        autoComplete='off'
                        autoFocus
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <button
                        type='submit'
                        className={`btn btn-primary ${
                          status === 'loading' ? 'disabled' : ''
                        }`}
                        disabled={status === 'loading'}>
                        {status === 'loading' ? (
                          <AiOutlineLoading className='loading' />
                        ) : (
                          <FaRegPaperPlane />
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
      <ScrollTop />
    </>
  );
}
