import React from 'react';

import { FiClock, FiMapPin } from '../assets/icons/vander';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { decodedToken } from '../helpers/utils';
import { useDispatch } from 'react-redux';
import { deleteJob } from '../store/slice/job-slice';

const JobsGrid = ({ jobData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    const response = window.confirm(
      'Are you sure you want to delete the Job ?'
    );
    if (response) dispatch(deleteJob(id));
  };

  return (
    <div className='row g-4'>
      {jobData.map((item, index) => {
        return (
          <div
            className='col-lg-4 col-md-6 col-12'
            key={index}
            style={{ cursor: 'pointer' }}>
            <div className='job-post rounded shadow p-4'>
              <div onClick={() => navigate(`/job-detail/${item?.id}`)}>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <div className=''>
                      <h3 className='h5 company text-dark'>{item?.jobTitle}</h3>
                      <span className='text-muted d-flex align-items-center small mt-2'>
                        <FiClock className='fea icon-sm me-1' />
                        {item?.date && moment(item.date).fromNow()}
                      </span>
                    </div>
                  </div>

                  <span className='badge bg-soft-primary'>
                    {item?.employmentType}
                  </span>
                </div>

                <div className='mt-4'>
                  <h4
                    to={`/job-detail-one/${item?.id}`}
                    className='text-dark title h5'>
                    {item?.companyName}
                  </h4>

                  <span className='text-muted d-flex align-items-center mt-2'>
                    <FiMapPin className='fea icon-sm me-1' />
                    {item?.location}
                  </span>
                </div>

                <div className='mt-4'>
                  <hr />
                  <ul className='list-unstyled mb-0'>
                    <>
                      {item?.skills
                        .split(',')
                        .slice(0, 3)
                        .map((skill, index) => (
                          <li className='d-inline-block me-1' key={index}>
                            <span className='badge bg-primary'>{skill}</span>
                          </li>
                        ))}
                    </>
                  </ul>
                </div>
              </div>
              {decodedToken()?.isAdmin && (
                <div className='mt-4'>
                  <hr />
                  <Link
                    to={`/job-form/${item.id}`}
                    className='btn btn-xs btn-outline-dark btn-block w-50'>
                    Edit
                  </Link>
                  <button
                    onClick={() => handleDelete(item.id)}
                    className='btn btn-xs btn-outline-danger btn-block w-50'>
                    Delete
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default JobsGrid;
