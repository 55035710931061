const clientConfigData = {
  coachhire: {
    LOCAL_API_URL: 'http://192.168.1.15:3500/',
    ANAND_PC: 'hhttps://gw3drjvh-3500.inc1.devtunnels.ms/',
    DEV_SERVER_API_URL: 'https://api-coachandhire.mkinfopoint.com/',
    PROD_API_URL: 'https://api-coachandhire.mkinfopoint.com/',
    TOKEN_KEY: 'ch-token',
    DEFAULT_PRODUCT_IMAGE: 'https://placehold.it/600/800',
    RAZOR_PAY_KEY:
      process.env.REACT_APP_ENV === 'production'
        ? 'rzp_live_xEkGzto3LRK9Al'
        : 'rzp_test_w8mBYe503vDrop',
    PUSHER_INSTANCE_ID: 'ce843141-4564-4857-b2c2-2bba82611dcd',
    FIREBASE_DEV_CONFIG: {
      apiKey: 'AIzaSyBZKfVYCORPR3PbimW2XJtjq9vLN2QMa7w',
      authDomain: 'coach-and-hire.firebaseapp.com',
      projectId: 'coach-and-hire',
      storageBucket: 'coach-and-hire.appspot.com',
      messagingSenderId: '369297342925',
      appId: '1:369297342925:web:0a7945b39ce9d808550484',
      measurementId: 'G-NNG3F6EB4W',
    },
    FIREBASE_PROD_CONFIG: {
      apiKey: 'AIzaSyBZKfVYCORPR3PbimW2XJtjq9vLN2QMa7w',
      authDomain: 'coach-and-hire.firebaseapp.com',
      projectId: 'coach-and-hire',
      storageBucket: 'coach-and-hire.appspot.com',
      messagingSenderId: '369297342925',
      appId: '1:369297342925:web:0a7945b39ce9d808550484',
      measurementId: 'G-NNG3F6EB4W',
    },
  },
};

console.log(process.env.REACT_APP_CLIENT);
export const clientConfig = clientConfigData[process.env.REACT_APP_CLIENT];
