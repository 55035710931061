import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import bg1 from '../assets/images/hero/bg5.jpg';

import Footer from '../componants/footer';
import ScrollTop from '../componants/scrollTop';

import {
  FiFacebook,
  FiGlobe,
  FiInstagram,
  FiLinkedin,
  FiMail,
  FiMapPin,
  FiTwitter,
  LuHeartHandshake,
  LuMessageCircle,
  LuPen,
} from '../assets/icons/vander';

import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../componants/navbar';
import { API_URL } from '../config/config';
import { decodedToken, isLoggedIn, maskText } from '../helpers/utils';
import { getFollowers, sendRequest } from '../store/slice/followers-slice';

export default function CoachProfile() {
  const { data: coachData } = useSelector((state) => state.coach);
  const {
    followerStatus,
    followers,
    status: followStatus,
  } = useSelector((state) => state.follower);
  let params = useParams();
  let id = params.id;
  let data = coachData.find((candidates) => candidates.id === parseInt(id));
  const dispatch = useDispatch();

  const handleRequestConnection = async () => {
    if (!data) return;
    const payload = {
      toUser: data?.uid,
      status: 'request',
    };

    await dispatch(sendRequest(payload));
    dispatch(getFollowers());
  };

  useEffect(() => {
    if (isLoggedIn()) {
      if (followerStatus === 'idle') {
        dispatch(getFollowers());
      }
    }
  }, [followerStatus, dispatch]);

  const checkIsFollowing = () => {
    let isFollowing = 'not-followers';

    if (followers) {
      const followUser = followers.find(
        (follow) => follow.toUser === data?.uid
      );
      if (followUser) {
        isFollowing = followUser.status;
      }
    }

    return isFollowing;
  };

  return (
    <>
      {/* <NavbarDark/> */}
      {/* <Navbar navClass='defaultscroll sticky' navLight={true} /> */}

      <Navbar navClass='defaultscroll sticky' navLight={true} />

      <section
        className='bg-half-170 d-table w-100'
        style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
        <div className='bg-overlay bg-gradient-overlay'></div>
        <div className='container'>
          <div className='row mt-5 justify-content-center'>
            <div className='col-12'>
              <div className='title-heading text-center'>
                <h5 className='heading fw-semibold mb-0 sub-heading text-white title-dark'>
                  Coach Details
                </h5>
              </div>
            </div>
          </div>

          <div className='position-middle-bottom'>
            <nav aria-label='breadcrumb' className='d-block'>
              <ul className='breadcrumb breadcrumb-muted mb-0 p-0'>
                <li className='breadcrumb-item'>
                  <Link to='/'>Coach & Hire</Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Coach Details
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className='position-relative'>
        <div className='shape overflow-hidden text-white'>
          <svg
            viewBox='0 0 2880 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z'
              fill='currentColor'></path>
          </svg>
        </div>
      </div>

      <section className='section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='position-relative'>
                <div className='candidate-profile d-flex align-items-end justify-content-between mx-2'>
                  <div className='d-flex align-items-end'>
                    <img
                      src={
                        data?.avatar &&
                        data?.avatar !== null &&
                        data?.avatar !== 'null'
                          ? `${API_URL}${data?.avatar}`
                          : `https://avatar.iran.liara.run/username?username=${data?.name}`
                      }
                      className='rounded-pill shadow border border-3 avatar avatar-medium'
                      alt=''
                    />

                    <div className='ms-2'>
                      <h5 className='mb-0'>
                        {data?.title &&
                        data?.title !== 'null' &&
                        data?.title !== null
                          ? data?.title
                          : ''}{' '}
                        {data?.name &&
                        data?.name !== null &&
                        data?.name !== 'null'
                          ? data?.name
                          : '-'}
                      </h5>
                      <p className='text-muted mb-0'>
                        {data?.designation &&
                        data?.designation !== null &&
                        data?.designation !== 'null'
                          ? data?.designation
                          : '-'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container mt-4'>
          <div className='row g-4'>
            <div className='col-lg-8 col-md-7 col-12'>
              <h5 className='mb-4'>Introduction:</h5>

              <p
                className='text-muted'
                dangerouslySetInnerHTML={{
                  __html:
                    data?.introduction &&
                    data?.introduction !== null &&
                    data?.introduction !== 'null'
                      ? data?.introduction
                      : '-',
                }}></p>

              <h5 className='mt-4'>Skills:</h5>

              <div className='row'>
                {data?.skill.split(',').map((item, index) => {
                  return (
                    <div className='col-lg-6 col-12' key={index}>
                      <div className='progress-box mt-4' key={index}>
                        <h6 className='font-weight-normal'>
                          {item && item !== null && item !== 'null'
                            ? item
                            : '-'}
                        </h6>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className='col-lg-4 col-md-5 col-12'>
              <div className='card bg-light light-white-bg p-4 rounded shadow sticky-bar'>
                <h5 className='mb-0'>Personal Detail:</h5>
                <div className='mt-3'>
                  {data?.comm_email_show_on_connection ? (
                    <div className='d-flex align-items-center justify-content-between mt-3'>
                      <span className='d-inline-flex align-items-center text-muted fw-medium'>
                        <FiMail className='fea icon-sm me-2' /> Email:
                      </span>
                      <span
                        className='fw-medium '
                        style={{ wordBreak: 'break-all', paddingLeft: '40px' }}>
                        {checkIsFollowing() === 'followers'
                          ? data?.comm_email
                          : maskText(data?.comm_email, 'email')}{' '}
                      </span>
                    </div>
                  ) : (
                    ''
                  )}

                  {/* <div className='d-flex align-items-center justify-content-between mt-3'>
                    <span className='d-inline-flex align-items-center text-muted fw-medium'>
                      <FiPhone className='fea icon-sm me-2' /> Phone No:
                    </span>
                    <span className='fw-medium'>
                      {checkIsFollowing() === 'followers'
                        ? data?.comm_phone
                        : maskText(data?.comm_phone, 'phone')}{' '}
                    </span>
                  </div> */}

                  <div className='d-flex align-items-center justify-content-between mt-3'>
                    <span className='d-inline-flex align-items-center text-muted fw-medium'>
                      <FiMapPin className='fea icon-sm me-2' /> City:
                    </span>
                    <span className='fw-medium'>
                      {checkIsFollowing() === 'followers'
                        ? data?.city
                        : maskText(data?.city, 'text')}{' '}
                    </span>
                  </div>

                  <div className='d-flex align-items-center justify-content-between mt-3'>
                    <span className='d-inline-flex align-items-center text-muted fw-medium'>
                      <FiGlobe className='fea icon-sm me-2' /> Country:
                    </span>
                    <span className='fw-medium'>{data?.country}</span>
                  </div>

                  {checkIsFollowing() === 'followers' && (
                    <div className='d-flex align-items-center justify-content-between mt-3'>
                      <span className='text-muted fw-medium'>Social:</span>

                      <ul className='list-unstyled social-icon text-sm-end mb-0'>
                        {data?.linkedin && (
                          <li className='list-inline-item'>
                            <Link
                              to={
                                checkIsFollowing() === 'followers'
                                  ? data?.linkedin
                                  : maskText(data?.linkedin, 'url')
                              }
                              target='_blank'
                              className='rounded'>
                              <FiLinkedin className='fea icon-sm align-middle' />
                            </Link>
                          </li>
                        )}

                        {data?.facebook && (
                          <li className='list-inline-item'>
                            <Link
                              to={
                                checkIsFollowing() === 'followers'
                                  ? data?.facebook
                                  : maskText(data?.facebook, 'url')
                              }
                              target='_blank'
                              className='rounded'>
                              <FiFacebook className='fea icon-sm align-middle' />
                            </Link>
                          </li>
                        )}

                        {data?.instagram && (
                          <li className='list-inline-item'>
                            <Link
                              to={
                                checkIsFollowing() === 'followers'
                                  ? data?.instagram
                                  : maskText(data?.instagram, 'url')
                              }
                              target='_blank'
                              className='rounded'>
                              <FiInstagram className='fea icon-sm align-middle' />
                            </Link>
                          </li>
                        )}

                        {data?.twitter && (
                          <li className='list-inline-item'>
                            <Link
                              to={
                                checkIsFollowing() === 'followers'
                                  ? data?.twitter
                                  : maskText(data?.twitter, 'url')
                              }
                              target='_blank'
                              className='rounded'>
                              <FiTwitter className='fea icon-sm align-middle' />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}

                  <div className='p-3 rounded shadow bg-white mt-2'>
                    {isLoggedIn() ? (
                      checkIsFollowing() === 'followers' ? (
                        <span className='btn btn-success w-100'>
                          <LuHeartHandshake className='fea icon-sm me-1' />
                          Connected
                        </span>
                      ) : checkIsFollowing() === 'not-followers' ? (
                        <span
                          className='btn btn-primary w-100'
                          onClick={handleRequestConnection}>
                          <LuHeartHandshake className='fea icon-sm me-1' />
                          {followStatus === 'loading'
                            ? 'Loading......'
                            : 'REQUEST CONNECTION'}
                        </span>
                      ) : checkIsFollowing() === 'request' ? (
                        <span className='btn btn-dark w-100'>
                          <LuHeartHandshake className='fea icon-sm me-1' />
                          Requested
                        </span>
                      ) : (
                        checkIsFollowing() === 'rejected' && (
                          <span className='btn btn-danger w-100'>
                            <LuHeartHandshake className='fea icon-sm me-1' />
                            Rejected
                          </span>
                        )
                      )
                    ) : (
                      <span className='btn btn-primary w-100'>
                        <LuHeartHandshake className='fea icon-sm me-1' />
                        LOGIN TO MAKE CONNECTION
                      </span>
                    )}

                    {isLoggedIn() && decodedToken()?.isAdmin && (
                      <>
                        <a
                          href={`/edit-profile-by-admin/${data?.uid}`}
                          className='btn btn-danger w-100 mt-3'>
                          <LuPen className='fea icon-sm me-1' />
                          Edit this user Profile
                        </a>
                      </>
                    )}

                    {isLoggedIn() && (
                      <>
                        <a
                          href={`/message/${data?.uid}`}
                          className='btn btn-warning w-100 mt-3'>
                          <LuMessageCircle className='fea icon-sm me-1' />
                          Send a Message
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer top={true} />
      <ScrollTop />
    </>
  );
}
