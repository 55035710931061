import AxiosInstance from '../../helpers/AxiosInstance';
import { Alert } from '../../helpers/utils';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const sendMessage = createAsyncThunk(
  'send/message',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.post(`messages`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const getAllConv = createAsyncThunk(
  'get/allConv',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(`messages/view-conv`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getAllMsg = createAsyncThunk(
  'get/allMsg',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(`messages/view-msg/${data}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const messageSlice = createSlice({
  name: 'message',
  initialState: {
    status: 'idle',
    conversations: [],
    messages: [],
  },
  reducers: {
    addMessageFromPusher: (state, action) => {
      state.messages.push(action.payload);
    },
  },
  extraReducers: {
    [sendMessage.pending]: (state, action) => {
      state.status = 'loading';
    },
    [sendMessage.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.messages.push(action?.payload?.data);
    },
    [sendMessage.rejected]: (state, action) => {
      state.status = 'error';
      Alert('error', `${action.payload.data.msg}`);
    },

    [getAllConv.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getAllConv.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.conversations = action.payload.data;
    },
    [getAllConv.rejected]: (state, action) => {
      state.status = 'error';
      Alert('error', `${action.payload.data.msg}`);
    },

    [getAllMsg.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getAllMsg.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      console.log('action.payload :>> ', action.payload);
      state.messages = action.payload.data;
    },
    [getAllMsg.rejected]: (state, action) => {
      state.status = 'error';
      Alert('error', `${action.payload.data.msg}`);
    },
  },
});

export const { addMessageFromPusher } = messageSlice.actions;

export default messageSlice.reducer;
