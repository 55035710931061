import React, { useEffect, useState } from 'react';

import bg1 from '../assets/images/hero/bg5.jpg';

import Footer from '../componants/footer';
import ScrollTop from '../componants/scrollTop';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomEditor from '../componants/customEditor';
import Navbar from '../componants/navbar';
import { postJob, updateJob } from '../store/slice/job-slice';

export default function JobForm() {
  let params = useParams();
  const { data: jobData, status } = useSelector((state) => state.job);

  const initialFormValues = {
    jobTitle: '',
    companyName: '',
    employmentType: '',
    location: '',
    experience: '',
    qualification: '',
    salary: '',
    skills: '',
    phoneNumber: '',
    email: '',
    website: '',
    date: '',
    jobDesc: '',
    duties: '',
    skillDetails: '',
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [type, setType] = useState('add');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let id = params.id;
    if (id) {
      let data = jobData.find((job) => job.id === parseInt(id));
      if (data) setFormValues(data);
      setType('edit');
    }
  }, [params]);

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (type === 'add') dispatch(postJob(formValues));
    if (type === 'edit') dispatch(updateJob(formValues));
    if (status === 'fulfilled') navigate('/jobs');
  };

  return (
    <>
      <Navbar navClass='defaultscroll sticky' navLight={true} />

      <section
        className='bg-half-170 d-table w-100'
        style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
        <div className='bg-overlay bg-gradient-overlay'></div>
        <div className='container'>
          <div className='row mt-5 justify-content-center'>
            <div className='col-12'>
              <div className='title-heading text-center'>
                <h5 className='heading fw-semibold mb-0 sub-heading text-white title-dark'>
                  Manage Jobs
                </h5>
              </div>
            </div>
          </div>

          <div className='position-middle-bottom'>
            <nav aria-label='breadcrumb' className='d-block'>
              <ul className='breadcrumb breadcrumb-muted mb-0 p-0'>
                <li className='breadcrumb-item'>
                  <Link to='/'>Coach & Hire</Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Jobs
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className='position-relative'>
        <div className='shape overflow-hidden text-white'>
          <svg
            viewBox='0 0 2880 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z'
              fill='currentColor'></path>
          </svg>
        </div>
      </div>
      <section className='section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='rounded shadow p-4'>
                <form onSubmit={handleSubmit}>
                  <h5>Job Detail :</h5>
                  <div className='row mt-4'>
                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Job Title:<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Name:'
                          name='jobTitle'
                          value={formValues.jobTitle}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Company Name:<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Name:'
                          name='companyName'
                          value={formValues.companyName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Employment Type:<span className='text-danger'>*</span>
                        </label>
                        <select
                          className='form-control form-select'
                          name='employmentType'
                          value={formValues.employmentType}
                          onChange={handleChange}>
                          <option value='Full Time'>Full Time</option>
                          <option value='Part Time'>Part Time</option>
                          <option value='Remote'>Remote</option>
                          <option value='Contract'>Contract</option>
                        </select>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Location :<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Location :'
                          name='location'
                          value={formValues.location}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    {/* <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Experience :<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Experience :'
                          name='experience'
                          value={formValues.experience}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Qualification :<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Qualification :'
                          name='qualification'
                          value={formValues.qualification}
                          onChange={handleChange}
                        />
                      </div>
                    </div> */}
                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Salary :<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Salary :'
                          name='salary'
                          value={formValues.salary}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Skills :<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Your Skills :'
                          name='skills'
                          value={formValues.skills}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Contact No :
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='PhoneNumber :'
                          name='phoneNumber'
                          value={formValues.phoneNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Email :
                        </label>
                        <input
                          type='email'
                          className='form-control'
                          placeholder='Email :'
                          name='email'
                          value={formValues.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Website :
                        </label>
                        <input
                          type='website'
                          className='form-control'
                          placeholder='Website :'
                          name='website'
                          value={formValues.website}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>Date :</label>
                        <input
                          type='date'
                          className='form-control'
                          placeholder='Date :'
                          name='date'
                          value={formValues.date}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <hr />

                    <div className='col-md-12'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Description :<span className='text-danger'>*</span>
                        </label>
                        <CustomEditor
                          value={formValues.jobDesc}
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: 'jobDesc',
                                value: e,
                              },
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Responsibilities and Duties :
                          <span className='text-danger'>*</span>
                        </label>
                        <CustomEditor
                          value={formValues.duties}
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: 'duties',
                                value: e,
                              },
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Required Experience, Skills and Qualifications :
                          <span className='text-danger'>*</span>
                        </label>
                        <CustomEditor
                          value={formValues.skillDetails}
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: 'skillDetails',
                                value: e,
                              },
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className='col-12'>
                      <input
                        type='submit'
                        id='submit2'
                        name='send'
                        className='submitBnt btn btn-primary'
                        value='Save Changes'
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer top={true} />
      <ScrollTop />
    </>
  );
}
