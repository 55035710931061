import React from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../config/config';

const CoachGrid = ({ coachData }) => {
  return (
    <div className='row g-4'>
      {coachData.map((item, index) => {
        return (
          <div className='col-lg-3 col-md-4 col-sm-6 col-12' key={index}>
            <div className='candidate-card position-relative overflow-hidden text-center shadow rounded p-4'>
              <div className='content'>
                <img
                  src={
                    item.avatar &&
                    item.avatar !== null &&
                    item.avatar !== 'null'
                      ? `${API_URL}${item?.avatar}`
                      : `https://avatar.iran.liara.run/username?username=${item.name}`
                  }
                  className='avatar avatar-md-md rounded-pill shadow-md'
                  alt=''
                />

                <div className='mt-3'>
                  <Link
                    to={`/coach-profile/${item?.id}`}
                    className='title h5 text-dark'>
                    {item.name && item.name !== null && item.name !== 'null'
                      ? item?.name
                      : '-'}
                  </Link>
                  <p className='text-muted mt-1'>
                    {item.designation &&
                    item.designation !== null &&
                    item.designation !== 'null'
                      ? item?.designation
                      : '-'}
                  </p>

                  {item?.skill
                    ?.split(',')
                    ?.slice(0, 3)
                    ?.map((skill, index) => (
                      <span
                        className='badge bg-soft-primary rounded-pill'
                        key={index}>
                        {skill && skill !== null && skill !== 'null'
                          ? skill
                          : '-'}
                      </span>
                    ))}
                </div>

                <div className='mt-2 d-flex align-items-center justify-content-between'>
                  <div className='text-center'>
                    <p className='text-muted fw-medium mb-0'>Location:</p>
                    <p className='mb-0 fw-medium'>
                      {item.city && item.city !== null && item.city !== 'null'
                        ? item?.city
                        : '-'}
                    </p>
                  </div>

                  <div className='text-center'>
                    <p className='text-muted fw-medium mb-0'>Experience:</p>
                    <p className='mb-0 fw-medium'>
                      {item.experience &&
                      item.experience !== null &&
                      item.experience !== 'null'
                        ? item?.experience
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className='mt-3'>
                  <Link
                    to={`/coach-profile/${item?.id}`}
                    className='btn btn-sm btn-primary me-1'>
                    View Profile
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CoachGrid;
