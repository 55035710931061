import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/images/logo-light.png';

import { BiMessage } from 'react-icons/bi';
import { FiBookmark } from '../assets/icons/vander';
import { decodedToken, isLoggedIn } from '../helpers/utils';

export default function Footer({ top = true }) {
  return (
    <footer className='bg-footer'>
      {top === true ? (
        <div className='py-5'>
          <div className='container'>
            <div className='row align-items-end'>
              <div className='col-md-7'>
                <div className='section-title'>
                  <div className='d-flex align-items-center'>
                    <FiBookmark className='fea icon-lg' />
                    <div className='flex-1 ms-3'>
                      <h4 className='fw-bold text-white mb-2'>
                        Explore a job now!
                      </h4>
                      <p className='text-white-50 mb-0 footer_para'>
                        Search all the open positions on the web. Get your own
                        personalized salary estimate.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-md-5 mt-4 mt-sm-0'>
                <div className='text-md-end ms-5 ms-sm-0'>
                  {isLoggedIn() && !decodedToken().isCoach && (
                    <Link to='/profile' className='btn btn-primary me-1 my-1'>
                      Become a Coach
                    </Link>
                  )}
                  <Link to='/coaches' className='btn btn-soft-primary my-1'>
                    Find a Coach
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='py-5 footer-bar'>
              <div className='row align-items-center'>
                <div className='col-sm-3'>
                  <div className='text-center text-sm-start'>
                    <Link to=''>
                      <img src={logo} alt='' />
                    </Link>
                  </div>
                </div>

                <div className='col-sm-9 mt-4 mt-sm-0'>
                  <ul className='list-unstyled footer-list terms-service text-center text-sm-end mb-0'>
                    <li className='list-inline-item my-2'>
                      <Link to='/' className='text-foot fs-6 fw-medium me-2'>
                        <i className='mdi mdi-circle-small'></i> Home
                      </Link>
                    </li>
                    <li className='list-inline-item my-2'>
                      <Link
                        to='/aboutus'
                        className='text-foot fs-6 fw-medium me-2'>
                        <i className='mdi mdi-circle-small'></i> About Us
                      </Link>
                    </li>
                    <li className='list-inline-item my-2'>
                      <Link
                        to='/jobs'
                        className='text-foot fs-6 fw-medium me-2'>
                        <i className='mdi mdi-circle-small'></i> Jobs
                      </Link>
                    </li>
                    <li className='list-inline-item my-2'>
                      <Link
                        to='/coaches'
                        className='text-foot fs-6 fw-medium me-2'>
                        <i className='mdi mdi-circle-small'></i> Find a Coach
                      </Link>
                    </li>
                    <li className='list-inline-item my-2'>
                      <Link
                        to='/contactus'
                        className='text-foot fs-6 fw-medium'>
                        <i className='mdi mdi-circle-small'></i> Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='py-4 footer-bar'>
        <div className='container text-center'>
          <div className='row align-items-center'>
            <div className='col-sm-12'>
              <div className='text-sm-center'>
                <p className='mb-0 fw-medium'>
                  © {new Date().getFullYear()} Coach & Hire.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Link to='/messages' className='floating-icon'>
        <BiMessage />
      </Link>
    </footer>
  );
}
