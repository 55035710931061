import React, { useEffect, useState } from 'react';

import bg1 from '../assets/images/hero/bg5.jpg';

import Footer from '../componants/footer';
import ScrollTop from '../componants/scrollTop';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FiCamera } from '../assets/icons/vander';
import Navbar from '../componants/navbar';
import { API_URL } from '../config/config';
import { isLoggedIn } from '../helpers/utils';
import {
  getProfileById,
  updateProfileByAdmin,
} from '../store/slice/user-slice';
export default function EditCoachProfileByAdmin() {
  const [fileUrl, setFileUrl] = useState(null);
  const [file, setFile] = useState(null);
  let params = useParams();
  let id = params.id;

  const { profile, profileStatus } = useSelector((state) => state.user);

  function handleFileChange(e) {
    setFileUrl(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  }

  const initialFormValues = {
    title: 'Mr.',
    comm_email: '',
    name: '',
    designation: '',
    introduction: '',
    skill: '',
    comm_phone: '',
    address: '',
    city: '',
    country: '',
    interests: '',
    experience: '',
    linkedin: '',
    facebook: '',
    twitter: '',
    instagram: '',
    isCoach: false,
  };
  const [formValues, setFormValues] = useState(initialFormValues);

  const dispatch = useDispatch();

  useEffect(() => {
    if (profileStatus === 'idle') {
      dispatch(getProfileById(id));
    }

    console.log('{profile} :>> ', { profile });
    if (profileStatus === 'fulfilled' && profile) {
      setFileUrl(`${API_URL}${profile.avatar}`);
      setFormValues(profile);
    }
  }, [id, profile]);

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e, coachSubmit = false) => {
    e.preventDefault();

    const submissionData = new FormData();
    submissionData.append('uid', id);
    submissionData.append('title', formValues.title);
    submissionData.append('name', formValues.name);
    submissionData.append('comm_email', formValues.comm_email);
    submissionData.append('comm_phone', formValues.comm_phone);
    submissionData.append('designation', formValues.designation);
    submissionData.append('introduction', formValues.introduction);
    submissionData.append('skill', formValues.skill);
    submissionData.append('address', formValues.address);
    submissionData.append('city', formValues.city);
    submissionData.append('country', formValues.country);
    submissionData.append('interests', formValues.interests);
    submissionData.append('experience', formValues.experience);
    submissionData.append('linkedin', formValues.linkedin);
    submissionData.append('facebook', formValues.facebook);
    submissionData.append('twitter', formValues.twitter);
    submissionData.append('instagram', formValues.instagram);
    if (coachSubmit) submissionData.append('isCoach', 'true');
    if (file) submissionData.append('avatar', file);

    dispatch(updateProfileByAdmin(submissionData));
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleBecomeCoach = (e) => {
    handleClose();

    handleSubmit(e, true);
  };

  return (
    <>
      <Navbar navClass='defaultscroll sticky' navLight={true} />

      <section
        className='bg-half-170 d-table w-100'
        style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
        <div className='bg-overlay bg-gradient-overlay'></div>
        <div className='container'>
          <div className='row mt-5 justify-content-center'>
            <div className='col-12'>
              <div className='title-heading text-center'>
                <h5 className='heading fw-semibold mb-0 sub-heading text-white title-dark'>
                  Profile
                </h5>
              </div>
            </div>
          </div>

          <div className='position-middle-bottom'>
            <nav aria-label='breadcrumb' className='d-block'>
              <ul className='breadcrumb breadcrumb-muted mb-0 p-0'>
                <li className='breadcrumb-item'>
                  <Link to='/'>Coach & Hire</Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Profile
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className='position-relative'>
        <div className='shape overflow-hidden text-white'>
          <svg
            viewBox='0 0 2880 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z'
              fill='currentColor'></path>
          </svg>
        </div>
      </div>
      <section className='section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='position-relative'>
                <div className='candidate-cover'></div>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='candidate-profile d-flex align-items-end mx-2'>
                    <div className='position-relative'>
                      <input
                        type='file'
                        onChange={handleFileChange}
                        style={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          opacity: '0.01',
                          zIndex: '11',
                        }}
                      />
                      <div className='position-relative d-inline-block'>
                        <img
                          src={fileUrl}
                          className='avatar avatar-medium img-thumbnail rounded-pill shadow-sm'
                          id='profile-image'
                          alt=''
                        />
                        <label
                          className='icons position-absolute bottom-0 end-0'
                          htmlFor='pro-img'>
                          <span className='btn btn-icon btn-sm btn-pills btn-primary'>
                            <FiCamera className='icons' />
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className='ms-2'>
                      <h5 className='mb-0'>
                        Mr. {profile.name}{' '}
                        <small className='text-muted'>({profile.email})</small>{' '}
                      </h5>
                      <p className='text-muted mb-0'>{profile.designation}</p>
                    </div>
                  </div>
                  <div className='pull-right'>
                    {isLoggedIn() && !profile.isCoach && (
                      <span
                        className='btn btn-primary me-1 my-1'
                        onClick={handleShow}>
                        Become a Coach
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='rounded shadow p-4'>
                <form onSubmit={handleSubmit}>
                  <h5>Personal Detail :</h5>
                  <div className='row mt-4'>
                    <div className='col-md-2'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Title:<span className='text-danger'>*</span>
                        </label>
                        <select
                          className='form-control form-select'
                          id='Type'
                          name='title'
                          value={formValues.title}
                          onChange={handleChange}>
                          <option value='Mr.'>Mr.</option>
                          <option value='Miss.'>Miss.</option>
                          <option value='Mrs.'>Mrs.</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Name:<span className='text-danger'>*</span>
                        </label>
                        <input
                          id='firstname'
                          type='text'
                          className='form-control'
                          placeholder='Name:'
                          name='name'
                          value={formValues.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-md-3'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Designation:<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Designation :'
                          name='designation'
                          value={formValues.designation}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Experience:<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Experience :'
                          name='experience'
                          value={formValues.experience}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Communication Email:
                          <span className='text-danger'>*</span>
                        </label>
                        <input
                          type='email'
                          className='form-control'
                          placeholder='Your email :'
                          name='comm_email'
                          value={formValues.comm_email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Communication Mobile:
                          <span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Your mobile :'
                          name='comm_phone'
                          value={formValues.comm_phone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Address:
                          <span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Your Address :'
                          name='address'
                          value={formValues.address}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-md-3'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          City:
                          <span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Your City :'
                          name='city'
                          value={formValues.city}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Country:
                          <span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Your Country :'
                          name='country'
                          value={formValues.country}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Skills :<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Your Skills :'
                          name='skill'
                          value={formValues.skill}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Interests :<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Your Interests :'
                          name='interests'
                          value={formValues.interests}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-12'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Introduction :
                        </label>
                        <textarea
                          id='comments2'
                          rows='4'
                          className='form-control'
                          placeholder='Introduction :'
                          name='introduction'
                          value={formValues.introduction}
                          onChange={handleChange}></textarea>
                      </div>
                    </div>

                    <hr />

                    <div className='col-md-3'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Linkedin :
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Your Linkedin :'
                          name='linkedin'
                          value={formValues.linkedin}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Facebook :
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Your Facebook :'
                          name='facebook'
                          value={formValues.facebook}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Twitter :
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Your Twitter :'
                          name='twitter'
                          value={formValues.twitter}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Instagram :
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Your Instagram :'
                          name='instagram'
                          value={formValues.instagram}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-12'>
                      <input
                        type='submit'
                        id='submit2'
                        name='send'
                        className='submitBnt btn btn-primary'
                        value='Save Changes'
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Become a Coach</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Upon your approval, your profile will be displayed to other users
            who have an interest in your skills. These users will have the
            option to request access to your contact details.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleBecomeCoach}>
            Yes Proceed !
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer top={true} />
      <ScrollTop />
    </>
  );
}
