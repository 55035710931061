import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FiLink,
  FiLogOut,
  FiMessageCircle,
  FiUser,
} from '../assets/icons/vander';
import logoDark from '../assets/images/logo-dark.png';
import logoLight from '../assets/images/logo-light.png';
import logoWhite from '../assets/images/logo-white.png';
import { API_URL } from '../config/config';
import { decodedToken, handleLogout, isLoggedIn } from '../helpers/utils';

export default function Navbar({ navClass, navLight }) {
  let [isOpen, setMenu] = useState(true);
  let [scroll, setScroll] = useState(false);
  let [profileDropdown, setProfileDropdown] = useState(false);

  let [manu, setManu] = useState('');
  let location = useLocation();
  useEffect(() => {
    let current = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    setManu(current);
  }, [location.pathname.substring(location.pathname.lastIndexOf('/') + 1)]);

  useEffect(() => {
    function scrollHandler() {
      setScroll(window.scrollY > 50);
    }
    window.addEventListener('scroll', scrollHandler);

    let profileModal = () => {
      //   setProfileDropdown(false);
    };
    document.addEventListener('mousedown', profileModal);
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
      document.removeEventListener('mousedown', profileModal);
    };
  }, []);
  const toggleMenu = () => {
    setMenu(!isOpen);
    if (document.getElementById('navigation')) {
      const anchorArray = Array.from(
        document.getElementById('navigation').getElementsByTagName('a')
      );
      anchorArray.forEach((element) => {
        element.addEventListener('click', (elem) => {
          const target = elem.target.getAttribute('href');
          if (target !== '') {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle('open');
            }
          }
        });
      });
    }
  };

  return (
    <header id='topnav' className={`${scroll ? 'nav-sticky' : ''} ${navClass}`}>
      <div className='container'>
        {navLight === true ? (
          <Link className='logo' to='/'>
            <span className='logo-light-mode'>
              <img src={logoDark} className='l-dark' alt='' />
              <img src={logoLight} className='l-light' alt='' />
            </span>
            <img src={logoLight} className='logo-dark-mode' alt='' />
          </Link>
        ) : (
          <Link className='logo' to='/'>
            <span className='logo-light-mode'>
              <img src={logoDark} className='l-dark' alt='' />
              <img src={logoWhite} className='l-light' alt='' />
            </span>
            <img src={logoWhite} className='logo-dark-mode' alt='' />
          </Link>
        )}
        <div className='menu-extras'>
          <div className='menu-item'>
            <Link
              to='#'
              className='navbar-toggle'
              id='isToggle'
              onClick={toggleMenu}>
              <div className='lines'>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        {isLoggedIn() && (
          <ul className='buy-button list-inline mb-0'>
            <li className='list-inline-item ps-1 mb-0'>
              <div className='dropdown dropdown-primary'>
                <button
                  type='button'
                  onClick={() => setProfileDropdown(!profileDropdown)}
                  className='dropdown-toggle btn btn-sm btn-icon btn-pills btn-primary'>
                  <img
                    src={
                      decodedToken()?.avatar &&
                      decodedToken()?.avatar !== null &&
                      decodedToken()?.avatar !== 'null'
                        ? `${API_URL}${decodedToken()?.avatar}`
                        : `https://avatar.iran.liara.run/username?username=${
                            decodedToken()?.name
                          }`
                    }
                    className='img-fluid rounded-pill bg-white'
                    alt=''
                  />
                </button>
                <div
                  style={{
                    display: profileDropdown === true ? 'block' : 'none',
                  }}>
                  <div
                    className={` dropdown-menu dd-menu dropdown-menu-end bg-white rounded shadow border-0 mt-3 show`}>
                    <a className='dropdown-item fw-medium fs-6' href='/profile'>
                      <FiUser className='fea icon-sm me-2 align-middle' />
                      Profile
                    </a>
                    <div className='dropdown-divider border-top'></div>
                    <a
                      className='dropdown-item fw-medium fs-6'
                      href='/followers'>
                      <FiLink className='fea icon-sm me-2 align-middle' />
                      Followers
                    </a>
                    <div className='dropdown-divider border-top'></div>

                    <a
                      className='dropdown-item fw-medium fs-6'
                      href='/messages'>
                      <FiMessageCircle className='fea icon-sm me-2 align-middle' />
                      Messages
                    </a>
                    <div className='dropdown-divider border-top'></div>
                    <Link
                      to='#'
                      onClick={() => handleLogout()}
                      className='dropdown-item fw-medium fs-6'>
                      <FiLogOut className='fea icon-sm me-2 align-middle' />
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        )}

        <div id='navigation'>
          <ul className='navigation-menu nav-right nav-light'>
            <li className={manu === 'index' ? 'active' : ''}>
              <Link to='/' className='sub-menu-item'>
                Home
              </Link>
            </li>

            <li className={manu === 'jobs' ? 'active' : ''}>
              <Link to='/jobs' className='sub-menu-item'>
                Jobs
              </Link>
            </li>
            <li className={manu === 'coaches' ? 'active' : ''}>
              <Link to='/coaches' className='sub-menu-item'>
                Find a Coach
              </Link>
            </li>
            <li className={manu === 'aboutus' ? 'active' : ''}>
              <Link to='/aboutus' className='sub-menu-item'>
                About Us
              </Link>
            </li>
            <li className={manu === 'contactus' ? 'active' : ''}>
              <Link to='/contactus' className='sub-menu-item'>
                Contact Us
              </Link>
            </li>
            {isLoggedIn() && decodedToken()?.isAdmin && (
              <>
                <li className={manu === 'job-form' ? 'active' : ''}>
                  <Link to='/job-form' className='sub-menu-item'>
                    Create Job
                  </Link>
                </li>
              </>
            )}
            {!isLoggedIn() && (
              <li className={manu === 'login' ? 'active' : ''}>
                <Link to='/login' className='sub-menu-item'>
                  Login / Register
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </header>
  );
}
