import React, { useEffect, useState } from 'react';

import bg1 from '../assets/images/hero/bg.jpg';

import Footer from '../componants/footer';
import Navbar from '../componants/navbar';
import ScrollTop from '../componants/scrollTop';

import { useSelector } from 'react-redux';
import CoachGrid from '../componants/CoachGrid';
import FormSelect from '../componants/formSelect';

export default function Coaches() {
  const { data: coachData } = useSelector((state) => state.coach);

  const [coachDataFiltered, setCoachDataFiltered] = useState(coachData);

  const handleSearch = (e, key) => {
    e.preventDefault();

    const filtered = coachData.filter((coach) =>
      coach.name.toLowerCase().includes(key.toLowerCase())
    );
    setCoachDataFiltered(filtered);
  };

  const handleReset = () => {
    setCoachDataFiltered(coachData);
  };

  useEffect(() => {
    setCoachDataFiltered(coachData);
  }, [coachData]);

  return (
    <>
      <Navbar navClass='defaultscroll sticky' navLight={true} />

      <section
        className='bg-half-170 d-table w-100'
        style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
        <div className='bg-overlay bg-gradient-overlay'></div>
        <div className='container'>
          <div className='row mt-5 justify-content-center'>
            <div className='col-12'>
              <div className='title-heading text-center'>
                <h5 className='heading fw-semibold mb-0 sub-heading text-white title-dark'>
                  FIND A COACH
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='position-relative'>
        <div className='shape overflow-hidden text-white'>
          <svg
            viewBox='0 0 2880 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z'
              fill='currentColor'></path>
          </svg>
        </div>
      </div>
      <section className='section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 mt-4'>
              <div className='features-absolute'>
                <div className='d-md-flex justify-content-between align-items-center bg-white shadow rounded p-4'>
                  <FormSelect onSearch={handleSearch} onReset={handleReset} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <CoachGrid coachData={coachDataFiltered} />
        </div>
      </section>
      <Footer top={true} />
      <ScrollTop />
    </>
  );
}
