import React from 'react';
import { Link } from 'react-router-dom';

import bg1 from '../assets/images/hero/bg.jpg';

import About from '../componants/aboutUs';
import Footer from '../componants/footer';
import Navbar from '../componants/navbar';
import ScrollTop from '../componants/scrollTop';

import { FiHelpCircle } from '../assets/icons/vander';
import { servicesData } from '../data/data';

export default function AboutUs() {
  return (
    <>
      <Navbar navClass='defaultscroll sticky' navLight={true} />
      <section
        className='bg-half-170 d-table w-100'
        style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
        <div className='bg-overlay bg-gradient-overlay'></div>
        <div className='container'>
          <div className='row mt-5 justify-content-center'>
            <div className='col-12'>
              <div className='title-heading text-center'>
                <h5 className='heading fw-semibold mb-0 sub-heading text-white title-dark'>
                  About Us
                </h5>
              </div>
            </div>
          </div>

          <div className='position-middle-bottom'>
            <nav aria-label='breadcrumb' className='d-block'>
              <ul className='breadcrumb breadcrumb-muted mb-0 p-0'>
                <li className='breadcrumb-item'>
                  <Link to='/'>Coach & Hire</Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  About us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className='position-relative'>
        <div className='shape overflow-hidden text-white'>
          <svg
            viewBox='0 0 2880 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z'
              fill='currentColor'></path>
          </svg>
        </div>
      </div>

      <section className='section'>
        <About containerClass='container' />

        <div className='container mt-100 mt-60'>
          <div className='row justify-content-center mb-4 pb-2'>
            <div className='col-12'>
              <div className='section-title text-center'>
                <h4 className='title mb-3'>
                  Here's why you'll love it Coach & Hire
                </h4>
                <p className='text-muted para-desc mx-auto mb-0'>
                  Search all the open positions on the web. Get your own
                  personalized salary estimate.
                </p>
              </div>
            </div>
          </div>

          <div className='row justify-content-center'>
            {servicesData.map((item, index) => {
              let Icon = item.icon;
              return (
                <div
                  className='col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2'
                  key={index}>
                  <div className='position-relative features text-center p-4 rounded shadow bg-white'>
                    <div className='feature-icon bg-soft-primary rounded shadow mx-auto position-relative overflow-hidden d-flex justify-content-center align-items-center'>
                      <Icon className='fea icon-ex-md' />
                    </div>

                    <div className='mt-4'>
                      <Link to='' className='title h5 text-dark'>
                        {item.title}
                      </Link>
                      <p className='text-muted mt-3 mb-0'>{item.desc}</p>
                      {/* <div className='mt-3'>
                        <Link to='' className='btn btn-link primary text-dark'>
                          Read More <i className='mdi mdi-arrow-right'></i>
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className='container mt-100 mt-60 '>
          <div className='row justify-content-center'>
            <div className='col'>
              <div className='section-title text-center mb-4 pb-2'>
                <h4 className='title mb-3'>Questions & Answers</h4>
                <p className='text-muted para-desc mb-0 mx-auto'>
                  Search all the open positions on the web. Get your own
                  personalized salary estimate.
                </p>
              </div>
            </div>
          </div>

          <div className='row mt-4 pt-2'>
            <div className='col-md-6 col-12'>
              <div className='d-flex'>
                <FiHelpCircle className='fea icon-ex-md text-primary me-2 mt-1' />
                <div className='flex-1'>
                  <h5 className='mt-0'>
                    How our <span className='text-primary'>Coach & Hire</span>{' '}
                    work ?
                  </h5>
                  <p className='answer text-muted mb-0'>
                    Coach & Hire connects users with coaches for career
                    guidance, skill-building, and mentorship through easy
                    profile creation and follow requests.
                  </p>
                </div>
              </div>
            </div>

            <div className='col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0'>
              <div className='d-flex'>
                <FiHelpCircle className='fea icon-ex-md text-primary me-2 mt-1' />
                <div className='flex-1'>
                  <h5 className='mt-0'>
                    {' '}
                    What is the main process open account ?
                  </h5>
                  <p className='answer text-muted mb-0'>
                    Simply sign up, create a profile (as a coach or user), and
                    verify your email to start connecting with coaches or users.
                  </p>
                </div>
              </div>
            </div>

            <div className='col-md-6 col-12 mt-4 pt-2'>
              <div className='d-flex'>
                <FiHelpCircle className='fea icon-ex-md text-primary me-2 mt-1' />
                <div className='flex-1'>
                  <h5 className='mt-0'> How to become a coach?</h5>
                  <p className='answer text-muted mb-0'>
                    Sign up, complete your profile with your expertise and
                    availability, then start accepting requests from users
                    seeking guidance.
                  </p>
                </div>
              </div>
            </div>

            <div className='col-md-6 col-12 mt-4 pt-2'>
              <div className='d-flex'>
                <FiHelpCircle className='fea icon-ex-md text-primary me-2 mt-1' />
                <div className='flex-1'>
                  <h5 className='mt-0'>
                    {' '}
                    Is <span className='text-primary'>Coach & Hire</span> safer
                    to use with my account ?
                  </h5>
                  <p className='answer text-muted mb-0'>
                    Yes, your account is protected with encryption, verified
                    accounts, and privacy settings to ensure secure
                    communication and data safety.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-md-5 pt-md-3 mt-4 pt-2 justify-content-center'>
            <div className='col-12 text-center'>
              <div className='section-title'>
                <h4 className='title mb-4'>Have Question ? Get in touch!</h4>
                <p className='text-muted para-desc mx-auto'>
                  Start working with{' '}
                  <span className='text-primary fw-bold'>Coach & Hire</span>{' '}
                  that can provide everything you need to generate awareness,
                  drive traffic, connect.
                </p>
                <Link to='/contactus' className='btn btn-primary mt-3'>
                  <i className='uil uil-phone'></i> Contact us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
