import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import bg1 from '../assets/images/hero/bg.jpg';

import Footer from '../componants/footer';
import Navbar from '../componants/navbar';
import ScrollTop from '../componants/scrollTop';

import { useDispatch, useSelector } from 'react-redux';
import {
  FiCheckCircle,
  FiClock,
  FiMapPin,
  FiXCircle,
} from '../assets/icons/vander';
import { API_URL } from '../config/config';
import { decodedToken, isLoggedIn } from '../helpers/utils';
import {
  getFollowers,
  getRequests,
  updateRequest,
} from '../store/slice/followers-slice';

export default function Followers() {
  const { data: coachData } = useSelector((state) => state.coach);
  const { followerStatus, followers, followRequestStatus, followRequests } =
    useSelector((state) => state.follower);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn()) {
      if (followerStatus === 'idle') {
        dispatch(getFollowers());
      }
      if (followRequestStatus === 'idle') {
        dispatch(getRequests());
      }
    }
  }, [followerStatus, dispatch, followRequestStatus]);

  const handleFollowStatusChange = async (id, status) => {
    const data = {
      id,
      status,
    };

    await dispatch(updateRequest(data));
    dispatch(getFollowers());
    dispatch(getRequests());
  };

  return (
    <>
      <Navbar navClass='defaultscroll sticky' navLight={true} />

      <section
        className='bg-half-170 d-table w-100'
        style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
        <div className='bg-overlay bg-gradient-overlay'></div>
        <div className='container'>
          <div className='row mt-5 justify-content-center'>
            <div className='col-12'>
              <div className='title-heading text-center'>
                <h5 className='heading fw-semibold mb-0 sub-heading text-white title-dark'>
                  FOLLOWERS
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='position-relative'>
        <div className='shape overflow-hidden text-white'>
          <svg
            viewBox='0 0 2880 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z'
              fill='currentColor'></path>
          </svg>
        </div>
      </div>
      <section className='section'>
        <div className='container mt-60'>
          <div className='row g-4'>
            {followRequests.length > 0 ? (
              <>
                {' '}
                {followRequests.map((item, index) => {
                  return (
                    <div className='col-12' key={index}>
                      <div className='job-post job-post-list rounded shadow p-4 d-md-flex align-items-center justify-content-between position-relative'>
                        <div className='d-flex align-items-center w-310px'>
                          <img
                            src={
                              item.fromUserDetails?.avatar &&
                              item.fromUserDetails?.avatar !== null &&
                              item.fromUserDetails?.avatar !== 'null'
                                ? `${API_URL}${
                                    item?.fromUserDetails?.avatar &&
                                    item?.fromUserDetails?.avatar
                                  }`
                                : `https://avatar.iran.liara.run/username?username=${item.fromUserDetails.name}`
                            }
                            className='avatar avatar-small rounded shadow p-3 bg-white'
                            alt=''
                          />

                          <div className='ms-3'>
                            <Link to={`#`} className='h5 title text-dark'>
                              {item?.fromUserDetails?.title &&
                              item?.fromUserDetails?.title !== 'null' &&
                              item?.fromUserDetails?.title !== null
                                ? item?.fromUserDetails?.title
                                : ''}{' '}
                              {item?.fromUserDetails?.name}{' '}
                              <span className='text-muted'>
                                {item?.fromUserDetails?.designation &&
                                item?.fromUserDetails?.designation !== 'null' &&
                                item?.fromUserDetails?.designation !== null
                                  ? '(' +
                                    item?.fromUserDetails?.designation +
                                    ' )'
                                  : ''}
                              </span>
                            </Link>
                            <span className='text-muted d-flex align-items-center'>
                              {item?.fromUserDetails?.interests &&
                              item?.fromUserDetails?.interests !== 'null' &&
                              item?.fromUserDetails?.interests !== null
                                ? item?.fromUserDetails?.interests
                                : ''}
                            </span>
                          </div>
                        </div>

                        <div className='d-flex align-items-center justify-content-between d-md-block mt-3 mt-md-0 w-100px'>
                          <span className='text-muted d-flex align-items-center fw-medium mt-md-2'>
                            {item?.fromUserDetails?.experience &&
                            item?.fromUserDetails?.experience !== 'null' &&
                            item?.fromUserDetails?.experience !== null ? (
                              <>
                                {' '}
                                <FiClock className='fea icon-sm me-1 align-middle' />{' '}
                                {item?.fromUserDetails?.experience}
                              </>
                            ) : (
                              ''
                            )}
                          </span>
                        </div>

                        <div className='d-flex align-items-center justify-content-between d-md-block mt-2 mt-md-0 w-130px'>
                          <span className='text-muted d-flex align-items-center'>
                            {item?.fromUserDetails?.city &&
                            item?.fromUserDetails?.city !== 'null' &&
                            item?.fromUserDetails?.city !== null ? (
                              <>
                                <FiMapPin className='fea icon-sm me-1 align-middle' />
                                {item?.fromUserDetails?.city} ,{' '}
                                {item?.fromUserDetails?.country}
                              </>
                            ) : (
                              ''
                            )}
                          </span>
                        </div>

                        <div className='mt-3 mt-md-0'>
                          <Link
                            onClick={() =>
                              handleFollowStatusChange(item.id, 'rejected')
                            }
                            className='btn btn-sm btn-icon btn-pills btn-soft-danger   bookmark me-2'>
                            <FiXCircle className='icons' />
                          </Link>
                          <Link
                            onClick={() =>
                              handleFollowStatusChange(item.id, 'followers')
                            }
                            className='btn btn-sm btn-icon btn-pills btn-soft-success bookmark'>
                            <FiCheckCircle className='icons' />
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className='alert alert-info '>No new Requests</div>
              </>
            )}
          </div>

          <div className='row g-4 mt-5'>
            <div>
              <h3>Followers</h3>
            </div>

            {followers?.length > 0 ? (
              <>
                {' '}
                {followers
                  .filter((folow) => folow.status === 'followers')
                  .map((item, index) => {
                    let UserKey = 'fromUserDetails';
                    if (decodedToken()) {
                      UserKey =
                        item.fromUserDetails.uid === decodedToken().uid
                          ? 'toUserDetails'
                          : 'fromUserDetails';
                    }
                    return (
                      <div className='col-12' key={index}>
                        <div className='job-post job-post-list rounded shadow p-4 d-md-flex align-items-center justify-content-between position-relative'>
                          <div className='d-flex align-items-center w-310px'>
                            <img
                              src={
                                item[UserKey]?.avatar &&
                                item[UserKey]?.avatar !== null &&
                                item[UserKey]?.avatar !== 'null'
                                  ? `${API_URL}${
                                      item[UserKey]?.avatar &&
                                      item[UserKey]?.avatar
                                    }`
                                  : `https://avatar.iran.liara.run/username?username=${item[UserKey].name}`
                              }
                              className='avatar avatar-small rounded shadow p-3 bg-white'
                              alt=''
                            />

                            <div className='ms-3'>
                              <Link to={`#`} className='h5 title text-dark'>
                                {item[UserKey]?.title &&
                                item[UserKey]?.title !== 'null' &&
                                item[UserKey]?.title !== null
                                  ? item[UserKey]?.title
                                  : ''}{' '}
                                {item[UserKey]?.name}{' '}
                                <span className='text-muted'>
                                  {item[UserKey]?.designation &&
                                  item[UserKey]?.designation !== 'null' &&
                                  item[UserKey]?.designation !== null
                                    ? '(' + item[UserKey]?.designation + ' )'
                                    : ''}
                                </span>
                              </Link>
                              <span className='text-muted d-flex align-items-center'>
                                {item[UserKey]?.interests &&
                                item[UserKey]?.interests !== 'null' &&
                                item[UserKey]?.interests !== null
                                  ? item[UserKey]?.interests
                                  : ''}
                              </span>
                            </div>
                          </div>

                          <div className='d-flex align-items-center justify-content-between d-md-block mt-3 mt-md-0 w-100px'>
                            <span className='text-muted d-flex align-items-center fw-medium mt-md-2'>
                              {item[UserKey]?.experience &&
                              item[UserKey]?.experience !== 'null' &&
                              item[UserKey]?.experience !== null ? (
                                <>
                                  {' '}
                                  <FiClock className='fea icon-sm me-1 align-middle' />{' '}
                                  {item[UserKey]?.experience}
                                </>
                              ) : (
                                ''
                              )}
                            </span>
                          </div>

                          <div className='d-flex align-items-center justify-content-between d-md-block mt-2 mt-md-0 w-130px'>
                            <span className='text-muted d-flex align-items-center'>
                              {item[UserKey]?.city &&
                              item[UserKey]?.city !== 'null' &&
                              item[UserKey]?.city !== null ? (
                                <>
                                  <FiMapPin className='fea icon-sm me-1 align-middle' />
                                  {item[UserKey]?.city} ,{' '}
                                  {item[UserKey]?.country}
                                </>
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            ) : (
              <>
                <div className='alert alert-info '>No Followers</div>
              </>
            )}
          </div>
        </div>
      </section>
      <Footer top={true} />
      <ScrollTop />
    </>
  );
}
