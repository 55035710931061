import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import bg1 from '../assets/images/hero/bg3.jpg';
import logo from '../assets/images/logo-dark.png';

import axios from 'axios';
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { API_URL, TOKEN_KEY } from '../config/config';
import {
  Alert,
  handleGoogleError,
  isLoggedIn,
  isTokenExpired,
} from '../helpers/utils';

export default function Signup() {
  const initialFormValues = {
    name: '',
    email: '',
    password: '',
  };

  const [formValues, setFormValues] = useState(initialFormValues);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const paramRef = query.get('ref');

  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const [isLoading, setIsLoading] = useState(false);

  const handleRegister = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, formValues.email, formValues.password)
      .then((result) => {
        const user = result.user;
        Alert('success', 'Authenticated ! Redirecting...');
        handleCustomLogin(user.accessToken);
      })
      .catch((error) => {
        handleGoogleError(error);
      });
  };

  const handleGoogleLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        Alert('success', 'Authenticated ! Redirecting...');
        handleCustomLogin(user.accessToken);
      })
      .catch((error) => {
        handleGoogleError(error);
      });
  };

  const handleCustomLogin = async (localToken) => {
    setIsLoading(true);
    try {
      const res = await axios.post(`${API_URL}auth/signup`, {
        OAuth: localToken,
      });
      const customToken = res.data.token;
      localStorage.setItem(TOKEN_KEY, customToken);
      redirectAfterLogin();
    } catch (error) {
      Alert('error', error.message);
    }
  };

  const redirectAfterLogin = async () => {
    setIsLoading(false);
    if (paramRef) {
      window.location.href = `${paramRef}`;
      return;
    }
    window.location.href = `/`;
  };

  useEffect(() => {
    if (isLoggedIn() && !isTokenExpired()) {
      redirectAfterLogin();
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  return (
    <section
      className='bg-home d-flex align-items-center'
      style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}>
      <div className='bg-overlay bg-linear-gradient-2'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 col-md-5 col-12'>
            <div
              className='p-4 bg-white rounded shadow-md mx-auto w-100'
              style={{ maxWidth: '400px' }}>
              <form onSubmit={handleRegister}>
                <Link to='/'>
                  <img src={logo} className='mb-4 d-block mx-auto' alt='' />
                </Link>
                <h6 className='mb-3 text-uppercase fw-semibold'>
                  Register your account
                </h6>

                <div className='mb-3'>
                  <label className='form-label fw-semibold'>Your Name</label>
                  <input
                    name='name'
                    id='name'
                    type='text'
                    className='form-control'
                    placeholder='Name'
                    value={formValues.name}
                    onChange={handleChange}
                  />
                </div>

                <div className='mb-3'>
                  <label className='form-label fw-semibold'>Your Email</label>
                  <input
                    name='email'
                    id='email'
                    type='email'
                    className='form-control'
                    placeholder='Email'
                    value={formValues.email}
                    onChange={handleChange}
                  />
                </div>

                <div className='mb-3'>
                  <label className='form-label fw-semibold' htmlFor='loginpass'>
                    Password
                  </label>
                  <input
                    type='password'
                    name='password'
                    className='form-control'
                    id='loginpass'
                    placeholder='Confirm Password'
                    value={formValues.password}
                    onChange={handleChange}
                  />
                </div>

                <button className='btn btn-primary w-100' type='submit'>
                  Register
                </button>
                <button
                  type='button'
                  className='btn btn-secondary w-100 mt-3'
                  onClick={handleGoogleLogin}>
                  Continue with Google
                </button>

                <div className='col-12 text-center mt-3'>
                  <span>
                    <span className='text-muted small me-2'>
                      Already have an account ?{' '}
                    </span>{' '}
                    <Link to='/login' className='text-dark fw-semibold small'>
                      Sign in
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
