import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import noConvImg from '../assets/images/no-conversations.webp';

import Navbar from '../componants/navbar';
import ScrollTop from '../componants/scrollTop';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../config/config';
import { decodedToken } from '../helpers/utils';
import { getAllConv } from '../store/slice/message-slice';

export default function Conversations() {
  const dispatch = useDispatch();
  const { conversations } = useSelector((state) => state.message);
  const [convos, setConvos] = useState();

  useEffect(() => {
    dispatch(getAllConv());
  }, [dispatch]);

  const currentUserId = decodedToken().uid;

  useEffect(() => {
    const groupedConversations = groupConversationsByUser(conversations);
    setConvos(groupedConversations);
  }, [conversations]);

  // Helper function to group conversations by 'toUserId'
  const groupConversationsByUser = (data) => {
    const grouped = {};

    data.forEach((conversation) => {
      const targetUser =
        conversation.toUserId === currentUserId
          ? conversation.fromUserDetails
          : conversation.toUserDetails;

      if (!grouped[targetUser.uid]) {
        grouped[targetUser.uid] = {
          ...targetUser,
          lastMessage: conversation.message,
          lastMessageTime: conversation.createdAt,
        };
      } else {
        // Update with the latest message if the current message is newer
        const currentLastMessageTime = new Date(
          grouped[targetUser.uid].lastMessageTime
        ).getTime();
        const newMessageTime = new Date(conversation.createdAt).getTime();

        if (newMessageTime > currentLastMessageTime) {
          grouped[targetUser.uid] = {
            ...targetUser,
            lastMessage: conversation.message,
            lastMessageTime: conversation.createdAt,
          };
        }
      }
    });

    return Object.values(grouped);
  };

  const ConversationBox = ({ data }) => {
    return (
      <Link
        to={`/message/${data.uid}`}
        className='card shadow rounded conversation-card'>
        <div className='card-body d-flex '>
          <div className='d-flex profile-holder'>
            <div className='profile-pic'>
              <img
                src={
                  data?.avatar &&
                  data?.avatar !== null &&
                  data?.avatar !== 'null'
                    ? `${API_URL}${data?.avatar}`
                    : `https://avatar.iran.liara.run/username?username=${data?.name}`
                }
                className='img-fluid rounded-pill'
                alt=''
              />
            </div>
            <div className='content'>
              <h5 className='mb-0'>{data.name}</h5>
              <p className='mb-0'>{data.lastMessage}</p>
            </div>
          </div>
          <div>
            <div className='time'>
              <p>{moment(data.lastMessageTime).fromNow()}</p>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <>
      <Navbar navClass='defaultscroll sticky nav-dark ' navLight={true} />

      <div className='position-relative'>
        <div className='shape overflow-hidden text-white'>
          <svg
            viewBox='0 0 2880 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z'
              fill='currentColor'></path>
          </svg>
        </div>
      </div>

      <section className='section messages-section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12'>
              <div className='card shadow rounded border-0'>
                <div className='card-body'>
                  <h5 className='card-title'>Messages</h5>

                  {convos?.length > 0 ? (
                    <div className='conversation-body'>
                      {convos?.map((conversation, index) => (
                        <ConversationBox key={index} data={conversation} />
                      ))}
                    </div>
                  ) : (
                    <div className='text-center'>
                      <img
                        src={noConvImg}
                        className='img'
                        alt='No Conversation'
                        style={{ width: '300px' }}
                      />
                      <h3>No conversations found</h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollTop />
    </>
  );
}
