import React, { useState } from 'react';
import { FiSearch } from '../assets/icons/vander';

export default function FormSelect({ onSearch, onReset }) {
  const [key, setKey] = useState('');

  const handleReset = () => {
    setKey('');
    onReset();
  };
  return (
    <>
      <form className='card-body text-start' onSubmit={(e) => onSearch(e, key)}>
        <div className='registration-form text-dark text-start'>
          <div className='row g-lg-0'>
            <div className='col-lg-8 col-md-6 col-12'>
              <div className='mb-3 mb-sm-0'>
                <label className='form-label d-none fs-6'>Search :</label>
                <div className='filter-search-form position-relative filter-border'>
                  <FiSearch className='fea icon-20 icons' />
                  <input
                    name='name'
                    type='text'
                    id='job-keyword'
                    className='form-control filter-input-box  border-0'
                    placeholder='Search...'
                    value={key}
                    onChange={(e) => setKey(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12'>
              <input
                type='submit'
                id='search'
                name='search'
                style={{ height: '60px' }}
                className='btn btn-primary searchbtn w-50'
                value='Search'
              />
              <button
                type='button'
                onClick={handleReset}
                style={{ height: '60px' }}
                className='btn btn-outline-dark searchbtn w-50'>
                Reset
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
