import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import bg1 from '../assets/images/hero/bg.jpg';
import contact from '../assets/images/svg/contact.svg';

import { contactData } from '../data/data';

import Navbar from '../componants/navbar';
import Footer from '../componants/footer';
import ScrollTop from '../componants/scrollTop';

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    comments: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formUrl =
      'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfMbVy9Ym_EalMnYB6ElcFu7srRTBSnVaRV0k3hWKU_ptYNxQ/formResponse';

    const formDataToSubmit = new URLSearchParams();
    formDataToSubmit.append('entry.930711123', formData.name); // Name entry ID
    formDataToSubmit.append('entry.1480572337', formData.email); // Email entry ID
    formDataToSubmit.append('entry.1058750571', formData.subject); // Subject entry ID
    formDataToSubmit.append('entry.847263890', formData.comments); // Comments entry ID

    try {
      await fetch(formUrl, {
        method: 'POST',
        mode: 'no-cors', // 'no-cors' is necessary because of cross-origin issues
        body: formDataToSubmit,
      });
      alert('Form submitted successfully!');
      setFormData({
        name: '',
        email: '',
        subject: '',
        comments: '',
      });
    } catch (error) {
      alert('There was an error submitting the form. Please try again.');
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Navbar navClass='defaultscroll sticky' navLight={true} />

      <section
        className='bg-half-170 d-table w-100'
        style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
        <div className='bg-overlay bg-gradient-overlay'></div>
        <div className='container'>
          <div className='row mt-5 justify-content-center'>
            <div className='col-12'>
              <div className='title-heading text-center'>
                <h5 className='heading fw-semibold mb-0 sub-heading text-white title-dark'>
                  Contact us
                </h5>
              </div>
            </div>
          </div>

          <div className='position-middle-bottom'>
            <nav aria-label='breadcrumb' className='d-block'>
              <ul className='breadcrumb breadcrumb-muted mb-0 p-0'>
                <li className='breadcrumb-item'>
                  <Link to='/'>Coach & Hire</Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Contact us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className='position-relative'>
        <div className='shape overflow-hidden text-white'>
          <svg
            viewBox='0 0 2880 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z'
              fill='currentColor'></path>
          </svg>
        </div>
      </div>

      <section className='section pb-0'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              <img src={contact} className='img-fluid' alt='' />
            </div>
            <div className='col-md-6'>
              <div className='p-4 rounded shadow ms-lg-5'>
                <h4>Get in touch !</h4>
                <form className='mt-4' id='myForm' onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Your Name <span className='text-danger'>*</span>
                        </label>
                        <input
                          name='name'
                          type='text'
                          className='form-control'
                          placeholder='Name :'
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Your Email <span className='text-danger'>*</span>
                        </label>
                        <input
                          name='email'
                          type='email'
                          className='form-control'
                          placeholder='Email :'
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div className='col-12'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Subject
                        </label>
                        <input
                          name='subject'
                          type='text'
                          className='form-control'
                          placeholder='Subject :'
                          value={formData.subject}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='col-12'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Comments <span className='text-danger'>*</span>
                        </label>
                        <textarea
                          name='comments'
                          rows='4'
                          className='form-control'
                          placeholder='Message :'
                          value={formData.comments}
                          onChange={handleChange}
                          required></textarea>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='d-grid'>
                        <button type='submit' className='btn btn-primary'>
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className='container mt-100 mt-60 mb-60'>
          <div className='row d-flex justify-content-center g-4'>
            {contactData.map((item, index) => {
              let Icon = item.icon;
              return (
                <div className='col-md-4' key={index}>
                  <div className='position-relative features text-center mx-lg-4 px-md-1'>
                    <div className='feature-icon bg-soft-primary rounded shadow mx-auto position-relative overflow-hidden d-flex justify-content-center align-items-center'>
                      <Icon className='fea icon-ex-md' />
                    </div>

                    <div className='mt-4'>
                      <h5 className='mb-3'>{item.title}</h5>
                      <p className='text-muted'>{item.desc}</p>
                      <Link to={item.link} className='text-primary'>
                        {item.link}
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* <div className='container-fluid mt-100 mt-60'>
          <div className='row'>
            <div className='col-12 p-0'>
              <div className='card map border-0'>
                <div className='card-body p-0'>
                  <iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin'
                    title='Coach & Hire'
                    style={{ border: '0' }}
                    allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
