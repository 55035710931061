import React from 'react';
import { Link } from 'react-router-dom';

import bg1 from '../assets/images/bg2.png';
import company5 from '../assets/images/company/android.png';
import company1 from '../assets/images/company/circle-logo.png';
import company2 from '../assets/images/company/facebook-logo.png';
import company3 from '../assets/images/company/google-logo.png';
import company4 from '../assets/images/company/lenovo-logo.png';
import company6 from '../assets/images/company/linkedin.png';
import company7 from '../assets/images/company/skype.png';
import company8 from '../assets/images/company/snapchat.png';
import hero1 from '../assets/images/hero1.png';

import AboutUs from '../componants/aboutUs';
import Categories from '../componants/categories';
import Footer from '../componants/footer';
import Navbar from '../componants/navbar';
import ScrollTop from '../componants/scrollTop';

import JobsGrid from '../componants/JobsGrid';
import CoachGrid from '../componants/CoachGrid';
import { useSelector } from 'react-redux';
import { isLoggedIn } from '../helpers/utils';

export default function Index() {
  const { data: jobData } = useSelector((state) => state.job);
  const { data: coachData } = useSelector((state) => state.coach);
  return (
    <>
      <Navbar navClass='defaultscroll sticky' />
      <section
        className='bg-half-170 d-table w-100 bg-primary'
        style={{
          backgroundImage: `url(${bg1})`,
          backgroundPosition: 'center',
        }}>
        <div className='container'>
          <div className='row g-4 align-items-center'>
            <div className='col-md-6'>
              <Link
                className='title-heading'
                to={isLoggedIn() ? '/profile' : '/login'}>
                <h1 className='heading text-white fw-bold'>
                  Become a Coach <br />
                </h1>
                <p className='para-desc text-white-50 mb-0'>
                  Find job, employment, and career opportunities. You can also
                  sign up to tutor or find tutors in subjects you want.
                </p>

                <button className='btn btn-light mt-4'>Join Now</button>
              </Link>
            </div>

            <div className='col-md-6'>
              <div className='position-relative ms-lg-5'>
                <img src={hero1} className='img-fluid p-5' alt='' />

                <div className='spinner'>
                  <div className='position-absolute top-0 start-0 mt-lg-5 mt-4 ms-lg-5 ms-4'>
                    <img
                      src={company1}
                      className='avatar avatar-md-sm rounded shadow p-2 bg-white'
                      alt=''
                    />
                  </div>
                  <div className='position-absolute top-0 start-50 translate-middle-x'>
                    <img
                      src={company2}
                      className='avatar avatar-md-sm rounded shadow p-2 bg-white'
                      alt=''
                    />
                  </div>
                  <div className='position-absolute top-0 end-0 mt-lg-5 mt-4 me-lg-5 me-4'>
                    <img
                      src={company3}
                      className='avatar avatar-md-sm rounded shadow p-2 bg-white'
                      alt=''
                    />
                  </div>
                  <div className='position-absolute top-50 start-0 translate-middle-y'>
                    <img
                      src={company4}
                      className='avatar avatar-md-sm rounded shadow p-2 bg-white'
                      alt=''
                    />
                  </div>
                  <div className='position-absolute top-50 end-0 translate-middle-y'>
                    <img
                      src={company5}
                      className='avatar avatar-md-sm rounded shadow p-2 bg-white'
                      alt=''
                    />
                  </div>
                  <div className='position-absolute bottom-0 start-0 mb-lg-5 mb-4 ms-lg-5 ms-4'>
                    <img
                      src={company6}
                      className='avatar avatar-md-sm rounded shadow p-2 bg-white'
                      alt=''
                    />
                  </div>
                  <div className='position-absolute bottom-0 start-50 translate-middle-x'>
                    <img
                      src={company7}
                      className='avatar avatar-md-sm rounded shadow p-2 bg-white'
                      alt=''
                    />
                  </div>
                  <div className='position-absolute bottom-0 end-0 mb-lg-5 mb-4 me-lg-5 me-4'>
                    <img
                      src={company8}
                      className='avatar avatar-md-sm rounded shadow p-2 bg-white'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='section'>
        <AboutUs containerClass='container' />
        {/* <Categories /> */}

        <div className='container mt-100 mt-60'>
          <div className='row align-items-end mb-4 pb-2'>
            <div className='col-lg-6 col-md-9'>
              <div className='section-title text-md-start text-center'>
                <h4 className='title mb-3'>Explore Coaches</h4>
                <p className='text-muted para-desc mb-0'>
                  Search all the open positions on the web. Get your own
                  personalized salary estimate.
                </p>
              </div>
            </div>

            <div className='col-lg-6 col-md-3 d-none d-md-block'>
              <div className='text-md-end'>
                <Link to='/coaches' className='btn btn-link primary text-muted'>
                  See More Coaches <i className='mdi mdi-arrow-right'></i>
                </Link>
              </div>
            </div>
          </div>

          <div className='row g-4 mt-0'>
            <CoachGrid coachData={coachData.slice(0, 4)} />

            <div className='col-12 d-md-none d-block'>
              <div className='text-center'>
                <Link to='/coaches' className='btn btn-link primary text-muted'>
                  See More Coaches <i className='mdi mdi-arrow-right'></i>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='container mt-100 mt-60'>
          <div className='row align-items-end mb-4 pb-2'>
            <div className='col-lg-6 col-md-9'>
              <div className='section-title text-md-start text-center'>
                <h4 className='title mb-3'>Explore Jobs</h4>
                <p className='text-muted para-desc mb-0'>
                  Search all the open positions on the web. Get your own
                  personalized salary estimate.
                </p>
              </div>
            </div>

            <div className='col-lg-6 col-md-3 d-none d-md-block'>
              <div className='text-md-end'>
                <Link to='/jobs' className='btn btn-link primary text-muted'>
                  See More Jobs <i className='mdi mdi-arrow-right'></i>
                </Link>
              </div>
            </div>
          </div>

          <div className='row g-4 mt-0'>
            <JobsGrid jobData={jobData.slice(0, 6)} />

            <div className='col-12 d-md-none d-block'>
              <div className='text-center'>
                <Link to='/jobs' className='btn btn-link primary text-muted'>
                  See More Jobs <i className='mdi mdi-arrow-right'></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
